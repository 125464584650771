html {
	box-sizing: border-box;
	scroll-behavior: smooth;
	background: #eee;
	background-image: url(../images/background-light.png);
	background-size: cover;
}

.vl {
	background-color: #ccc;
	width: 1px;
	border-radius: 2rem;
	margin: 1.6rem 1rem;
}

.transition {
	opacity: 0;
}

.App {
	background: #101010;
	background-image: url(../images/coral-reef.jpg);
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
}

.overlay {
	background: rgba(0, 0, 0, 0.5);
	height: 100vh;
	background-size: 100%;
}

.App-header {
	position: fixed; /* Sit on top of the page content */
	font-family: 'Poppins';
	height: 10vh;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	color: white;
	width: 90vw;
	padding: 0 5vw 0 5vw;
	transition: height 0.2s ease-in-out;
}

.nav-up {
	height: 0vh;
}

.App-footer {
	height: 1.1rem;
	text-align: center;
	font-size: calc(5px + 1vmin);
	padding: 10px 0;
	color: #999;
}

.App-footer a {
	color: #999;
}

.App-footer a:hover {
	color: #777;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.start-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	-webkit-animation: fadein 200ms ease-in;
	-moz-animation: fadein 200ms ease-in;
	animation: fadein 200ms ease-in;
}

.start-info {
	max-width: 95vw;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	color: white;
	font-size: calc(5px + 2vmin);
	font-weight: 150%;
}

.start-info__section {
	margin: 5px;
}

.content-container {
	margin: auto;
	margin: 10vh auto 0 auto;
	width: 65vw;
	flex: 1;
}

.title {
	display: flex;
	flex-direction: column;
}

.interview {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* background-color: #eee; */
	margin: 2rem 0 2rem;
}

.interview__section {
	border: none;
}

.q-and-a {
	flex: 1;
	margin: 0;
}

.interviewee {
	padding-top: 1rem;
	width: 12vw;
	text-align: center;
}

.interview img {
	width: inherit;
	border-radius: 50%;
	filter: contrast(103%);
}

.circle {
	height: 0.3rem;
	width: 10rem;
	margin: auto;
	background-color: rgb(202, 223, 230);
	border-radius: 2rem;
}

.About {
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	-webkit-animation: fadein 200ms ease-in;
	-moz-animation: fadein 200ms ease-in;
	animation: fadein 200ms ease-in;
}

.About-box {
	width: 45vw;
	padding: 2rem;
	background: #eee;
}

.About-box__section {
	color: #010101;
}

.question {
	color: #101010;
	padding: 1rem 0 0.5rem 0;
	border: none;
	text-align: left;
	outline: none;
}

.answer {
	text-align: justify;
}

@media screen and (max-width: 850px) {
	.start-info {
		justify-content: center;
	}

	.App-header {
		position: absolute;
		flex-wrap: wrap;
	}

	.nav-up {
		height: 10vh;
	}

	.content-container {
		width: 94vw;
		margin: 3rem auto;
	}

	.interview {
		display: block;
	}

	.interviewee {
		width: 100%;
		text-align: left;
	}

	.interviewee h5 {
		padding: 0 2vw;
	}

	.interviewee h6 {
		padding: 0 2vw 2vw;
	}

	.q-and-a {
		padding: 2vw;
	}

	.About-box {
		width: 88vw;
		padding: 2vw;
	}
}

@media screen and (max-width: 480px) {
	.App-container {
		width: 95vw;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: center;
	}
	.App-container-item {
		background-color: white;
		margin: 5px;
		height: 10vh;
		width: 100vw;
	}

	.interviewee img {
		border-radius: 0.1rem;
	}
}
