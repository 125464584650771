html {
	font-size: calc(95% + 0.05vw);
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.question {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	margin: 0 0 0.5rem;
}

.answer {
	font-weight: 300;
	line-height: 1.3;
}

h1,
h2 {
	font-family: 'Poppins';
	color: #eee;
}

h3,
h4,
h5,
h6 {
	font-family: 'Poppins';
	color: #101010;
}

p,
span {
	font-family: sans-serif;
	text-align: justify;
}

h1 {
	font-size: calc(7vw + 1vmin);
	margin: 0 0 1rem;
}

h2 {
	font-size: calc(5vw + 1vmin);
	margin: 0 0 1rem;
}

h3 {
	line-height: 1;
	font-size: 2.5rem;
	margin: 0.5rem 0;
}

.id {
	line-height: 1;
	margin: 0;
	color: rgb(95, 189, 218);
}

h4 {
	font-size: 1.5rem;
	margin: 0 0 1rem;
	color: #101010;
}

h5 {
	font-size: 1.5rem;
	margin: 0 0 0.1rem;
}

h6 {
	font-size: 1rem;
	font-style: italic;
	font-weight: 500;
	margin: 0;
}

.signature {
	font-family: 'Poppins';
	font-size: 1.2rem;
	font-weight: 600;
}

@media screen and (max-width: 850px) {
	h3 {
		font-size: 1.7rem;
	}
	h5,
	h6 {
		color: #101010;
	}
	.h5 {
		font-size: 1.5rem;
	}

	.question,
	.answer {
		padding: 0 0 0.7rem;
	}
}
