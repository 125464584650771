.btn {
	font-family: 'Poppins';
	font-size: 1rem;
	cursor: pointer;
	transition: 0.1s background-color linear, 0.1s color linear;
}

.btn:focus {
	outline: transparent;
}

.btn-primary {
	background: #eee;
}

.btn-primary:hover {
	background: #222222;
}

.btn-primary:active {
	background: #222222;
}

.btn-secondary {
	background: #eee;
}

.btn-secondary:hover {
	background: #eee;
}

.btn-secondary:active {
	background: #eee;
}

.btn-tertiary {
	border: none;
	background: transparent;
	color: #eee;
	font-weight: 600;
}

.btn-tertiary:hover,
.btn-tertiary:active {
	color: rgba(255, 255, 255, 0.5);
}

.special {
	border: 0.2rem solid #eee;
	width: 24rem;
	height: 5rem;
	background: none;
	color: #eee;
	text-transform: uppercase;
	font-size: calc(9px + 2vmin);
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-align: center;
}

.special:hover {
	background: #eee;
	color: #222222;
}

.topBtn {
	color: #101010;
	visibility: hidden;
	opacity: 0;
	float: right;
	position: fixed;
	font-size: 2rem;
	line-height: 2rem;
	width: 4rem;
	height: 4rem;
	right: 2rem;
	bottom: 2rem;
	transition: visibility 0s, opacity 0.5s ease-in;
}

.topBtn:hover {
	color: #101010;
}

.topBtn-show {
	visibility: visible;
	opacity: 1;
}

@keyframes bounce {
	100% {
		transform: translateY(-10px);
	}
}

.topBtn:hover {
	-webkit-animation: bounce 1s infinite;
	animation: bounce 1s infinite;
}

.closeBtn {
	margin-left: 47vw;
	font-size: 2.5rem;
}

@media screen and (max-width: 850px) {
	.special {
		width: 90vw;
	}

	.topBtn {
		background-color: rgb(95, 189, 218);
		padding: 1rem;
		right: 0.8rem;
		bottom: 0.8rem;
		border-radius: 50%;
	}

	.closeBtn {
		margin-right: 5vw !important;
		margin-left: auto !important;
		font-size: 3rem !important;
	}
}
